<template>
  <v-card
    :height="matchHeight"
    :color="this.$vuetify.theme.currentTheme.primary"
  >
    <!-- <v-card-title style="background-color: #3a567d; color: #fff">Alertas</v-card-title> -->
    <v-simple-table v-if="alerts">
      <template v-slot:default>
        <thead>
          <tr :style="alerts ? 'background:#ccc' : ''">
            <th v-if="alerts[0].id" class="text-center">
              Id
            </th>
            <th class="text-center">
              Error
            </th>
            <th class="text-center">
              Time
            </th>
          </tr>
        </thead>
        <tbody style="background-color: #3a567d; color: #fff" class="clickable">
          <tr v-for="item in alerts" :key="item.id" @click="onAlertClick(item)">
            <td v-if="item.id" class="text-center">{{ item.id }}</td>
            <td v-if="item.status" class="text-center">{{ item.status }}</td>
            <td v-if="item.time" class="text-center">{{
              item.time | formatDatetime
            }}</td>
          </tr>
        </tbody>
        <!-- <span v-else >Não existe dados atualizados ...</span> -->
      </template>
    </v-simple-table>
    <!-- <v-data-table
      class="alerts-table"
      hide-default-footer
      disable-sort
      disable-pagination
      fixed-header
      item-key="id"
      :headers="headerx"
      :items="listItem"

      :class="{
        'clickable': clickable
      }"
    >
      <template #item="{ item }">
        <tr @click="onAlertClick(item)">
          <td >
            {{ item.id }}
          </td>
          <td>{{ item.status }}</td>
          <td>{{ item.time }}</td>
        </tr>
      </template>
    </v-data-table> -->
  </v-card>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
import { ROUTE_NAME } from "@/router";

export default {
  name: "AlertsTable",
  data() {
    return {
      headerx: [
        {
          text: "Id",
        },
        {
          text: "Erro",
        },
        {
          text: "Hora",
        },
      ],
      mokdata: [
        {
          description: "Lorem ipsum dei",
          time: "22:22:00",
          devId: 1,
        },
        {
          description: "Lorem ipsum dei",
          time: "12:10:00",
          devId: 6,
        },
        {
          description: "Lorem ipsum dei",
          time: "19:50:01",
          devId: 4,
        },
        {
          description: "Lorem ipsum dei",
          time: "20:10:50",
          devId: 60,
        },
      ],
      listItem: null,
    };
  },
  props: {
    matchHeight: {
      type: Number,
    },
    th_1: {
      type: Boolean,
      default: true,
    },
    alerts: {
      type: Array,
      default: () => [{}],
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    hideId: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.listItem = this.alerts;
  },
  // computed: {
  //   ...mapGetters([
  //     'config',
  //   ]),
  //   headers() {
  //     if (this.hideId) {
  //       return [
  //         { text: 'Alerta' },
  //         { text: 'Hora' }
  //       ]
  //     }

  //     return [
  //       { text: 'ID' },
  //       // { text: 'Alerta' },
  //       { text: 'Hora' }
  //     ]
  //   },
  // items() {
  //   return this.alerts.map(({ id, status, time}) => ({
  //     id,
  //     time: this.$options.filters.formatTime(time),
  //     status,
  //   }));
  // },
  // tableHeight() {
  //   console.log(this.$vuetify.breakpoint)
  //   return this.$vuetify.breakpoint.lgAndUp ? '' : 'calc(100vh - 158px)'
  // },
  // },
  methods: {
    //   ...mapActions([
    //     'loadAndSetActiveSite',
    //   ]),
    onAlertClick(alert) {
      if (alert) {
        this.$router.push({
          name: ROUTE_NAME.SITE_DASHBOAD,
          params: {
            siteId: alert.id,
          },
        });
      } else return;
    },
  },
};
</script>

<style lang="scss">
.clickable tr:hover {
  cursor: pointer;
}

.alerts-table.theme--dark.v-data-table {
  background-color: transparent !important;
}

.alerts-table.theme--dark.v-data-table thead th {
  background-color: transparent !important;
  color: white !important;
}
</style>
